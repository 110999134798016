import React from 'react'
import ProjectSection from '../components/projectsSection'

function GalleryPage() {
    return (
        <div>
            <ProjectSection/>
        </div>
    )
}

export default GalleryPage

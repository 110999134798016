import React from 'react'
import GetInTouchSection from '../components/getInTouchSection'

function ContactPage() {
    return (
        <div>
           <GetInTouchSection/>
        </div>
    )
}

export default ContactPage

import React from 'react'
import ServicesSection from '../components/servicesSection'

function ServicesPage() {
    return (
        <div>
            <ServicesSection/>
        </div>
    )
}

export default ServicesPage
